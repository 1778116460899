import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IIntegrationSetting,
  ISyncDataRequests,
} from "../../../types/businessSettings";
import { setResponseValue } from "../api-response/api-response";
import BusinessSetting from "../../../service/business-setting.service";

interface IntegrationSlice {
  integrationSettings: IIntegrationSetting[];
  syncDataLogs: { [appName: string]: ISyncDataRequests[] };
  isUpdated: boolean;
  lastSyncedAt: { [appName: string]: string };
}

const initialState: IntegrationSlice = {
  integrationSettings: [],
  syncDataLogs: {},
  isUpdated: false,
  lastSyncedAt: {},
};

const integrationSettingSlice = createSlice({
  name: "integrationSettingSlice",
  initialState,
  reducers: {
    setDataUpdated: (state, action) => {
      return {
        ...state,
        isUpdated: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetIntegrationSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          integrationSettings: action.payload,
        };
      }
    });
    // builder.addCase(SwitchIntegrationSetting.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     return {
    //       ...action.payload,
    //     };
    //   }
    // });
    builder.addCase(ConnectApp.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          integrationSettings: action.payload,
        };
      }
    });
    builder.addCase(ConnectMotivityApp.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          integrationSettings: action.payload,
        };
      }
    });
    builder.addCase(DisconnectApp.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          integrationSettings: action.payload,
        };
      }
    });
    builder.addCase(GetSyncRequestbyAppName.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const { appName } = action.meta.arg; // Extract appName from the thunk argument
        state.syncDataLogs[appName] = action.payload; // Set or update the syncDataLogs for the appName
      }
    });
    builder.addCase(GetLastSynced.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const { appName } = action.meta.arg; // Extract appName from the thunk argument
        state.lastSyncedAt[appName] = action.payload?.updatedAt;
      }
    });
  },
});

export const GetIntegrationSetting = createAsyncThunk(
  "/getIntegrationSetting",
  async (_, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } = await BusinessSetting.getIntegrationSetting();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const ConnectApp = createAsyncThunk(
  "/integrateApp",
  async (
    {
      appName,
      appCredential,
      testLogin,
    }: { appName: string; appCredential: any; testLogin: boolean },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } = await BusinessSetting.connectApp(
        appName,
        appCredential,
        testLogin
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const DisconnectApp = createAsyncThunk(
  "/disconnectApp",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } = await BusinessSetting.disconnectApp(
        appName
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const ConnectMotivityApp = createAsyncThunk(
  "/integrate/MotivityApp",
  async (
    {
      appName,
      appCredential,
      testLogin,
    }: { appName: string; appCredential: any; testLogin: boolean },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data, message } =
        await BusinessSetting.connectMotivityApp(
          appName,
          appCredential,
          testLogin
        );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetSyncRequestbyAppName = createAsyncThunk(
  "/getSyncRequests",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, data } =
        await BusinessSetting.getSyncDataRequestsByAppName(appName);
      if (status) {
        console.log(data, "IntegrationData");
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetLastSynced = createAsyncThunk(
  "/getlastSyncedDTM",
  async ({ appName }: { appName: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data, status } = await BusinessSetting.getlastSyncedDTM(appName);
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setDataUpdated } = integrationSettingSlice.actions;
export default integrationSettingSlice;
