import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import ImportExportService from "../../../service/importExportRequest.service";
import {
  IExportedData,
  IExportTemplate,
  IImportExportRequest,
  IImportSettings,
} from "../../../types/tools";

interface StatusUpdate {
  status: "updated" | "removed";
  oldValue?: any; 
  newValue?: any; 
}

interface DataChange {
  [key: string]: { // Outer object keys are dynamic
    [subKey: string]: StatusUpdate; // Inner object keys are dynamic
  };
}
interface IImportExportSlice {
  template: IExportTemplate[];
  exportedData: IExportedData[];
  requestLogs: IImportExportRequest[];
  importSettings: IImportSettings;
  selectedReviewRequests: {
    id: string;
    reviewAction: string;
  }[];
  reviewContext:DataChange|null
}

const initialState: IImportExportSlice = {
  template: [],
  exportedData: [],
  requestLogs: [],
  importSettings: {
    alreadyExist: "",
    new: "",
  },
  selectedReviewRequests: [],
  reviewContext:{}
};

const importExportSlice = createSlice({
  name: "importExportSlice",
  initialState: initialState,
  reducers: {
    setExportedData: (state, action) => {
      if (action.payload !== undefined) {
        const value = action.payload;
        let result: IExportedData[] = [];
        if (state.exportedData.length > 0) {
          // Iterate over the existing exportedData and check if the dataset exists
          result = state.exportedData.map((item) => {
            if (
              value.some(
                (ele: IExportedData) => ele.entityType === item.entityType
              )
            ) {
              return value;
            } else {
              return item;
            }
          });
          // Add new datasets that aren't in the existing exportedData
          value.forEach((element: IExportedData) => {
            if (
              !result.find((item) => item.entityType === element.entityType)
            ) {
              result.push(element);
            }
          });
        } else {
          // If exportedData is empty, add all datasets
          result = value;
        }
        return {
          ...state,
          exportedData: result,
        };
      }
    },
    updateReviewAction: (state, action) => {
      const data = state.requestLogs.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            reviewAction: action.payload.reviewAction,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        requestLogs: data,
      };
    },
    setSelectedReviewRequests: (state, action) => {
      state.selectedReviewRequests = action.payload;
    },
    setReviewContextToEmpty:(state)=>{
      state.reviewContext=null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetExportTemplates.fulfilled, (state, action) => {
      return {
        ...state,
        template: action.payload,
      };
    });
    builder.addCase(GetImportExportRequest.fulfilled, (state, action) => {
      return {
        ...state,
        requestLogs: action.payload.sort(
          (a: IImportExportRequest, b: IImportExportRequest) => {
            return (
              (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any)
            );
          }
        ),
      };
    });
    builder.addCase(GetImportSettings.fulfilled, (state, action) => {
      console.log(action.payload, "payload");
      if (action.payload !== undefined) {
        return {
          ...state,
          importSettings: action.payload.importSetting,
        };
      }
    });
    builder.addCase(UpdateImportSettings.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          importSettings: action.payload.importSetting,
        };
      }
    });
    builder.addCase(GetReviewContext.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          reviewContext: action.payload,
        };
      }
    });
  },
});

export const PostExportRequest = createAsyncThunk(
  "/PostExportRequest",
  async (
    { entityTypes, fileFormat }: { entityTypes: string[]; fileFormat: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { data, message } = await ImportExportService.postExportRequest(
        entityTypes,
        fileFormat
      );
      console.log("PostExportRequest", data, message);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const PostImportRequest = createAsyncThunk(
  "/PostImportRequest",
  async (formData: FormData, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.postImportRequest(
        formData
      );
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetExportTemplates = createAsyncThunk(
  "/exportTemplates",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.getExportTemplates();
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetImportExportRequest = createAsyncThunk(
  "/importExportRequests",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ImportExportService.getImportExoportRequest();
      dispatch(setResponseValue({ name: "success", value: true }));
      // dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetImportSettings = createAsyncThunk(
  "/getImportSettings",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ImportExportService.getImportSetting();
      dispatch(setResponseValue({ name: "success", value: true }));
      // dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateImportSettings = createAsyncThunk(
  "/update/importSettings",
  async (
    {
      newItem,
      alreadyExist,
    }: {
      newItem: string;
      alreadyExist: string;
    },
    { dispatch }
  ) => {
    const payloadData = {
      new: newItem,
      alreadyExist: alreadyExist,
    };
    try {
      // dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.updateImportSetting({
        importSettings: payloadData,
      });
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const ApplyReviewAction = createAsyncThunk(
  "/applyReviewAction",
  async (
    {
      reviewRequest,
    }: { reviewRequest: { id: string; reviewAction: string }[] },
    { dispatch }
  ) => {
    try {
      const payload = {
        reviewRequest: reviewRequest,
      };
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.applyReviewAction(
        payload
      );
      dispatch(GetImportExportRequest());
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
export const GetReviewContext = createAsyncThunk(
  "/review/context",
  async ({id}:{id:string}, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await ImportExportService.getReviewContext(id);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (error: any) {
      console.log(error);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value:
            error?.message || error?.response?.data?.message || "Error occured",
        })
      );
      throw error;
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
export const {
  setExportedData,
  updateReviewAction,
  setSelectedReviewRequests,
  setReviewContextToEmpty
} = importExportSlice.actions;
export default importExportSlice;
