import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IMessage } from "../../../types/message";
import {
  getTranslationMessageId,
  setTranslation,
} from "../../../redux/features/messages/message-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentTemplate } from "../../../redux/features/data-catalog/data-catalog-slice";

interface MessageFieldHeaderProps {
  setSelectedCommunicationTab: Dispatch<SetStateAction<string>>;
  selectedCommunicationTab: string;
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

const MessageFieldHeader: React.FC<MessageFieldHeaderProps> = ({
  selectedCommunicationTab,
  setSelectedCommunicationTab,
  selectedUser,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [composeError, setComposeError] = useState<string>("");
  const [language, setLanguage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const languageMap: Record<"en" | "es" | "zh-cn", string> = {
    en: "English",
    es: "Spanish",
    "zh-cn": "Chinese",
  };
  const {
    allMessageData: { internalCount, externalCount, UpdateCount },
    openedMessage,
    translatedMessage,
  } = useSelector<RootState, IMessage>((state) => state.messageSlice);

  const translateText = async (lang: string) => {
    try {
      setLoading(true);
      if (!lang) {
        setLanguage("");
        return dispatch(setTranslation(null));
      }
      if (openedMessage) {
        await dispatch(
          getTranslationMessageId({
            messageId: openedMessage.id,
            language: lang, //target language
          })
        );
      } else {
        dispatch(setTranslation(null));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleComposeOpen = () => {
    if (selectedUser?.id) {
      dispatch(setCurrentTemplate({ isEdit: false }));
      navigate("/compose-message");
    } else {
      setComposeError("Please Select whom you want to send");
    }
  };

  useEffect(() => {
    setLanguage("");
  }, [openedMessage?.id]);

  useEffect(() => {
    if (location?.state) {
      setSelectedCommunicationTab("External");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Grid item xs={16} md={16}>
        <div className="top-btn-list">
          <div>
            <Box
              className={
                selectedCommunicationTab === "Internal" ? "active" : ""
              }
              onClick={() => {
                setSelectedCommunicationTab("Internal");
              }}
            >
              {internalCount > 0 && (
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "20px",
                    backgroundColor: "#096dd9",
                    content: "",
                    position: "absolute",
                    left: "7px",
                    top: "14px",
                  }}
                ></span>
              )}
              Internal Notice
              <strong>{internalCount}</strong>
            </Box>
            <Box
              className={
                selectedCommunicationTab === "External"
                  ? "active"
                  : !selectedUser.id
                  ? "afterNone"
                  : ""
              }
              onClick={() => {
                setSelectedCommunicationTab("External");
              }}
            >
              {externalCount > 0 && (
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "20px",
                    backgroundColor: "#096dd9",
                    content: "",
                    position: "absolute",
                    left: "7px",
                    top: "14px",
                  }}
                ></span>
              )}
              External In-Mail
              <strong>{externalCount}</strong>
            </Box>
            <Box
              className={selectedCommunicationTab === "SMS" ? "active" : ""}
              onClick={() => setSelectedCommunicationTab("SMS")}
            >
              SMS
              <strong>{UpdateCount}</strong>
            </Box>
          </div>
          <div className="extra-btn">
            <div className="languageBlock">
              {openedMessage && (
                <FormControl fullWidth size="small">
                  <Select
                    className="demo-simple-select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty={true}
                    placeholder="Select Language"
                    value={language}
                    onChange={({ target: { value } }) => {
                      translateText(value);
                      setLanguage(value);
                    }}
                    renderValue={(value) => {
                      if (loading) {
                        return <CircularProgress size={20} />;
                      }
                      return (
                        languageMap[value as "en" | "es" | "zh-cn"] ||
                        "Translate"
                      );
                    }}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                  >
                    <MenuItem
                      value={"en"}
                      key={"English"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      value={"es"}
                      key={"Spanish"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Spanish
                    </MenuItem>
                    <MenuItem
                      value={"zh-cn"}
                      key={"Chinese"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Chinese
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            {translatedMessage && (
              <Box onClick={() => translateText("")}>
                <strong>See original</strong>
              </Box>
            )}
            <Grid>
              {selectedCommunicationTab === "External" && (
                <Button
                  variant="contained"
                  className="btn-right"
                  onClick={() => handleComposeOpen()}
                >
                  Compose
                </Button>
              )}
            </Grid>
          </div>
        </div>
        <span style={{ color: "#d32f2f" }}>{composeError}</span>
      </Grid>
    </>
  );
};

export default MessageFieldHeader;
