import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import PlanService from "../../../service/planning.service";
import { IPlanDetail, IPlanListData, IPlanningFilter, PlannerFilter } from "../../../types/planning";
import { defaultPlanListData } from "../../../constants/planning";
import {
  getAllBookingsData,
  // setResetBookedData,
} from "../schedules-main/schedule-list-slice";

const initialState: IPlanListData = {
  ...defaultPlanListData,
};

const planSlice = createSlice({
  name: "planSlice",
  initialState: initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      return {
        ...state,
        providerList: [...action.payload.providerList],
        clientList: [...action.payload.clientList],
      };
    },
    setCurrentPlan: (state, action) => {
      return {
        ...state,
        currentPlan: action.payload,
      };
    },
    setPlanName: (state, action) => {
      return {
        ...state,
        planName: action.payload,
      };
    },
    // setSchedulesIds: (state, action) => {
    //   return {
    //     ...state,
    //     schedulesIds: [...action.payload], //handle merged before this setMethod
    //   };
    // },

    //track resetAPI event
    setResetAPI: (state, action) => {
      return {
        ...state,
        planIsReset: action.payload,
        // schedulesIds: [],
      };
    },

    //track updateAPI event
    setUpdateAPI: (state, action) => {
      return {
        ...state,
        updateApi: action.payload,
      };
    },
    // resetPlanChart: (state, action) => {
    //   return {
    //     ...state,
    //     planChart: defaultPlanChart,
    //   };
    // },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getAllPlanList.fulfilled,
      (state, action: PayloadAction<IPlanDetail[] | any>) => {
        if (action.payload !== undefined) {
          return {
            ...state,
            allPlanList: [...action.payload.data],
          };
        }
      }
    );
    builder.addCase(
      getPlanById.fulfilled,
      (state, action: PayloadAction<IPlanDetail | any>) => {
        if (action.payload !== undefined) {
          return {
            ...state,
            currentPlan: action.payload,
          };
        }
      }
    );
    builder.addCase(
      updatePlanList.fulfilled,
      (state, action: PayloadAction<IPlanDetail | any>) => {
        if (action.payload !== undefined) {
          return {
            ...state,
            currentPlan: action.payload,
            // schedulesIds: [],
          };
        }
      }
    );
    // builder.addCase(updatePlan.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     const { data } = action.payload;

    //     // let updatedPlanner = state.planChart.map((plan: any) => {
    //     //   if (plan?.planDeatils?.supervisor?.id === data.id) {
    //     //     return {
    //     //       ...plan,
    //     //       planDeatils: { ...plan.planDeatils, supervisor: data },
    //     //     };
    //     //   } else {
    //     //     return plan;
    //     //   }
    //     // });

    //     return {
    //       ...state,
    //       // planChart: updatedPlanner,
    //     };
    //   }
    // });
  },
});

//create plan
export const createPlan = createAsyncThunk(
  "/planning/create",
  async (
    {
      planName,
      providerList,
      clientList,
      // schedulesIds,
    }: {
      planName: string;
      providerList: string[];
      clientList: string[];
      // schedulesIds: string[];
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      planName: planName,
      providerList: providerList,
      clientList: clientList,
      // schedulesIds: schedulesIds,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      const { status, data, message } = await PlanService.createPlan(
        payloadData
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        dispatch(setUpdateAPI(true));
        dispatch(setCurrentPlan(data));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update plan
export const updatePlanName = createAsyncThunk(
  `/planning/update`,
  async (
    { Id, planName }: { Id: string; planName: string },
    { getState, dispatch }
  ) => {
    try {
      const data = await PlanService.updatePlanName(Id, planName);

      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
        dispatch(setUpdateAPI(true));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update plan detail
export const updatePlanList = createAsyncThunk(
  "/planning/updatePlanList",
  async (
    {
      //planName,
      providerList,
      clientList,
      appliedFilters,
      // schedulesIds,
      //status, //not updatable here!
      id,
    }: {
      //planName: string;
      providerList: string[];
      clientList: string[];
      appliedFilters:IPlanningFilter
      // schedulesIds: string[];
      //status: boolean;
      id: string;
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      //planName: planName,
      providerList: providerList,
      clientList: clientList,
      appliedFilters:appliedFilters,
      // schedulesIds: schedulesIds,
      //status: status,
      id: id,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      const { status, data, message } = await PlanService.updatePlanList(
        payloadData,
        id
      );

      if (status) {
        dispatch(setUpdateAPI(true));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        // dispatch(getPlanById({ Id: id })); //return updated plan addressed
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get all plan list
export const getAllPlanList = createAsyncThunk(
  `/planning`,
  async ({ useLoader }: { useLoader: boolean }, { getState, dispatch }) => {
    try {
      if (useLoader) {
        dispatch(setResponseValue({ name: "pending", value: true }));
      }
      const data = await PlanService.getAllPlanList();

      return data.data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      if (useLoader) {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

//get plan detail by id
export const getPlanById = createAsyncThunk(
  `/planning/getdata`,
  async ({ Id }: { Id: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await PlanService.getPlanById(Id);

      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
        // dispatch(setUpdateAPI(true)); buggy! - not needed
        return data.data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//delete plan
export const deletePlan = createAsyncThunk(
  `/planning/delete`,
  async ({ Id }: { Id: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await PlanService.deletePlan(Id);

      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
        dispatch(getAllBookingsData());
        dispatch(setUpdateAPI(true));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const resetPlan = createAsyncThunk(
  `/resetPlan`,
  async (
    { id }: { id: string },
    { getState, dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await PlanService.resetPlan(id);

      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
        dispatch(getPlanById({ Id: id })); //refresh stat
        dispatch(getAllBookingsData());
        dispatch(setUpdateAPI(true));
        dispatch(setResetAPI(true));
        return data;
      }
    } catch (e: any) {
      console.log("error", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Update plan status
export const publishPlan = createAsyncThunk(
  "/planning/publish ",
  async (
    {
      Id,
    }: {
      Id: string;
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await PlanService.publishPlan(Id);

      if (data.status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));
        dispatch(getPlanById({ Id: Id })); //refresh stat
        dispatch(getAllBookingsData());
        dispatch(setUpdateAPI(true));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setSelectedPlan,
  setCurrentPlan,
  setPlanName,

  setUpdateAPI,
  setResetAPI,
  // setSchedulesIds,
  //resetPlanChart,
} = planSlice.actions;

export default planSlice;
