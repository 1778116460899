import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { GetIntegrationSetting } from "../../redux/features/integration-setting/integration-setting-slice";

import AppCard from "./AppCard";
import AppIntegrationModal from "./AppIntegrationModal";

import TherapyPMSLogo from "../../assets/images/integration-apps/therapypms.png";
import MotivityLogo from "../../assets/images/integration-apps/motivity.png";
import SyncRequest from "./SyncRequest";

const AppIntegrations = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<string>("Connect");
  const integrationSetting = useAppSelector(
    (state) => state.integrationSettingSlice.integrationSettings
  );

  const handleOpenModal = (appName: string) => {
    setIsModalOpen(true);
    setSelectedApp(appName);
  };

  const hasValidCredential = (appName: string) =>
    integrationSetting.some(
      (setting) =>
        setting.appName.toLowerCase() === appName.toLowerCase() &&
        ((typeof setting.credential === "string" &&
          setting.credential.trim() !== "") ||
          (typeof setting.credential === "object" &&
            Object.keys(setting.credential).length !== 0))
    );

  useEffect(() => {
    dispatch(GetIntegrationSetting());
  }, []);

  return (
    <div className="pageTemplate">
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        className="cardBlock__title"
        sx={{ marginBottom: "20px" }}
      >
        App Integration
      </Typography>
      <div className="pageTemplate-top" style={{ width: "fit-content" }}>
        <ul className="innerTabs mb-tab">
          <li className="innerTabs__item form-tabs">
            <button
              onClick={() => setSelectedTab("Connect")}
              className={selectedTab === "Connect" ? "active" : ""}
            >
              Connect
            </button>
          </li>
          <li
            className="innerTabs__item form-tabs"
            onClick={() => {
              setSelectedTab("TherapyPMS");
            }}
          >
            <button className={selectedTab === "TherapyPMS" ? "active" : ""}>
              TherapyPMS
            </button>
          </li>

          <li
            className="innerTabs__item form-tabs"
            onClick={() => setSelectedTab("Motivity")}
          >
            <button className={selectedTab === "Motivity" ? "active" : ""}>
              Motivity
            </button>
          </li>
        </ul>
      </div>

      {selectedTab === "Connect" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <AppCard
            appName="TherapyPMS"
            connected={hasValidCredential("therapypms")}
            logo={TherapyPMSLogo}
            onClick={() => handleOpenModal("TherapyPMS")}
          />
          <AppCard
            appName="Motivity"
            connected={hasValidCredential("motivity")}
            logo={MotivityLogo}
            onClick={() => handleOpenModal("Motivity")}
          />
        </Box>
      )}
      {selectedTab === "TherapyPMS" && <SyncRequest appName="TherapyPMS" />}
      {selectedTab === "Motivity" && <SyncRequest appName="Motivity" />}

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography>Allow to integrate applications</Typography>
        <FormControlLabel
          className="customToggler"
          control={
            <Switch
              onClick={handleSwitch}
              checked={integrationSetting.isActive}
            />
          }
          label={integrationSetting.isActive ? "ON" : "OFF"}
        />
      </Box> */}
      {isModalOpen && (
        <AppIntegrationModal
          appName={selectedApp}
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default AppIntegrations;
